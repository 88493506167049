import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import BannerLanding from "../components/BannerLanding";

import pic08 from "../assets/images/pic08.jpg";
import pic09 from "../assets/images/pic09.jpg";
import pic10 from "../assets/images/pic10.jpg";

const Lessons = props => (
  <Layout>
    <Helmet
      title="Online Oud Lessons"
      meta={[
        {
          name: "description",
          content:
            "From beginners to master classes, I teach to all skill levels."
        },
        { name: "keywords", content: "oud, music, lesson, online" }
      ]}
    ></Helmet>

    <BannerLanding />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Sed amet aliquam</h2>
          </header>
          <p>
            Başlangıç seviyesinden ileri seviyeye kadar işlediği eğitim
            yönteminde kullandığı kaynaklarda birçok önemli bestecinin eseri
            eğitime dahil edilmiştir. Nullam et orci eu lorem consequat
            tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum
            sem. In efficitur ligula tate urna. Maecenas massa vel lacinia
            pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat
            tincidunt. Vivamus et sagittis libero. Nullam et orci eu lorem
            consequat tincidunt vivamus et sagittis magna sed nunc rhoncus
            condimentum sem. In efficitur ligula tate urna.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <Link
            to="https://drive.google.com/drive/folders/1rJEoJPgyvcWWbSofpSjUhIfrXg2DFpm4?usp=sharing"
            className="image"
          >
            <img src={pic08} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Orci maecenas</h3>
              </header>
              <p>
                Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
                magna sed nunc rhoncus condimentum sem. In efficitur ligula tate
                urna. Maecenas massa sed magna lacinia magna pellentesque lorem
                ipsum dolor. Nullam et orci eu lorem consequat tincidunt.
                Vivamus et sagittis tempus.
              </p>
              <ul className="actions">
                <li>
                  <Link
                    to="https://drive.google.com/drive/folders/1rJEoJPgyvcWWbSofpSjUhIfrXg2DFpm4?usp=sharing"
                    className="button"
                    target="_blank"
                  >
                    Go to Music Scores
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <Link to="/generic" className="image">
            <img src={pic09} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Rhoncus magna</h3>
              </header>
              <p>
                Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
                magna sed nunc rhoncus condimentum sem. In efficitur ligula tate
                urna. Maecenas massa sed magna lacinia magna pellentesque lorem
                ipsum dolor. Nullam et orci eu lorem consequat tincidunt.
                Vivamus et sagittis tempus.
              </p>
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <Link to="/generic" className="image">
            <img src={pic10} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Sed nunc ligula</h3>
              </header>
              <p>
                Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
                magna sed nunc rhoncus condimentum sem. In efficitur ligula tate
                urna. Maecenas massa sed magna lacinia magna pellentesque lorem
                ipsum dolor. Nullam et orci eu lorem consequat tincidunt.
                Vivamus et sagittis tempus.
              </p>
              <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
);

export default Lessons;
